import BaseApi from './BaseApi'
import { AxiosRequestConfig } from 'axios'
import { FilterInterface } from '@/typings/FilterOptions'
import PaginationInterface from '@/typings/PaginationInterface'

export default class TokenApi extends BaseApi {
  constructor(type = 0) {
    super('Token', type)
  }

  async createGenericSupport(payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/support`,
      data: payload
    }
    // console.log('START CALL')
    // console.log(opts)
    return await this.postApi(opts)
  }

  async createChargePointRequest(payload: any) {
    // console.log(payload)
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/chargepoint`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async createChargePassRequest(payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/Request`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async myIssues(key: any, payload: any, filterProps: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/my/issues`,
      data: payload
    }
    return await this.postApi(options)
  }

  async myChargerReq({
    pagination,
    filter,
    isOwner,
    relationContactId,
    relationId
  }: {
    pagination: PaginationInterface
    filter: FilterInterface
    isOwner: boolean
    relationContactId?: string
    relationId?: string
  }) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/getRequestsList`,
      data: {
        pagesize: pagination.pagesize,
        pagenumber: pagination.pagenumber,
        caching: 0,
        organisation: isOwner,
        filter,
        relationContactId,
        relationId
      }
    }
    return await this.postApi(options)
  }

  async myOrganizationIssues(key: any, payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/organization/issues`,
      data: payload
    }
    return await this.postApi(options)
  }

  /* async myOrganizationIssues (key: any, emailAdress: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/organization/issues`
    }
    return await this.getApi(options)
  } */

  async list(emailAdress: any) {
    const options = {
      url: this.baseurl(`demoapi/myrequests?email=${emailAdress}`)
    }
    return await this.getApi(options)
  }

  async get(key: any, emailAdress: any) {
    const options = {
      url: this.baseurl(`demoapi/myrequests/${key}?email=${emailAdress}`)
    }
    return await this.getApi(options)
  }

  async getIssue(key: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/${key}`
    }
    return await this.getApi(options)
  }

  async getCPIssue(key: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/Request/${key}`
    }
    return await this.getApi(options)
  }

  async getRequestList() {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/cct/types`
    }
    return await this.getApi(options)
  }

  async getFieldList(id: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/cct/${id}/fields`
    }
    return await this.getApi(options)
  }

  async getComments(key: any, payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/${key}/comment`,
      params: payload
    }
    return await this.getApi(options)
  }

  async getAttachment(id: number|string) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/attachment/download/${id}`,
    }
    return await this.getApi(opts)
  }

  async create(payload: any, emailAdress: any) {
    const opts: AxiosRequestConfig = {
      url: this.baseurl(`demoapi/createrequest?email=${emailAdress}`),
      data: payload
    }
    return await this.postApi(opts)
  }

  async createFromType(type: any, payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/cct/${type}`,
      data: payload
    }
    return await this.postApi(options)
  }

  async addAttachment(id: string|number, payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/${id}/attachment`,
      data: payload
    }
    return await this.postApi(options)
  }

  async createComment(id: any, payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/${id}/comment`,
      data: payload
    }

    return await this.postApi(opts)
  }

  async createSurvey(id: any, payload: any, emailAdress: any) {
    const opts: AxiosRequestConfig = {
      url: this.baseurl(`demoapi/myrequests/${id}?email=${emailAdress}`),
      data: payload
    }
    return await this.putApi(opts)
  }

  async attach(id: any, payload: any, emailAdress: any) {
    const opts: AxiosRequestConfig = {
      url: this.baseurl(`demoapi/attachement/${id}?email=${emailAdress}`),
      data: payload
    }
    return await this.postApi(opts)
  }

  async createChargepointRequest(payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/issue/chargepoint`,
      data: payload
    }
    // console.log(opts)
    return await this.postApi(opts)
  }

  baseurl(url: string) {
    if (window.location.origin === 'http://localhost:8080') {
      return `https://my-dev.bluecorner.be/${url}`
    }
    return `${window.location.origin}/${url}`
  }
}
