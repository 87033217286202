<template>
  <div>
    <v-card-title class="display-1 mb-2">{{t('$vuetify.serviceCenter.requestCharger')}}</v-card-title>
    <v-card-subtitle>{{t('$vuetify.serviceCenter.contactDetails')}}</v-card-subtitle>
      <v-form ref="form" v-model="isFormValid" lazy-validation v-if="!isSaved">
        <v-card-text>
        <v-row>
          <v-flex xs6>
            <v-text-field
              class="mx-2"
              v-model="payload.companyName"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              :label="'Company'"
              :name="t('$vuetify.serviceCenter.company')"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              v-model="payload.vat"
              class="mx-2"
              :rules="[rules.required, rules.vatCheck]"
              :validate-on-blur="false"
              :error="error"
              :label="t('$vuetify.serviceCenter.vatNumber')"
              name="vat_number"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
        </v-row>
        <v-row>
          <v-flex xs3>
            <v-select
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              name="sexe"
              @keyup.enter="submit"
              @change="resetErrors"
              v-model="payload.sexe"
              class="mx-2"
              :items="['Mr.', 'Mrs.', 'Ms']"
              :label="t('$vuetify.serviceCenter.mrMrsms')"
            ></v-select>
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="mx-2"
              v-model="payload.name"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              :label="t('$vuetify.serviceCenter.name')"
              name="name"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
        </v-row>
        <v-row>
          <v-flex xs12>
        <v-text-field
          class="mx-2"
          v-model="payload.address"
          :rules="[rules.required]"
          :validate-on-blur="false"
          :error="error"
          :label="t('$vuetify.serviceCenter.name')"
          name="address"
          @keyup.enter="submit"
          @change="resetErrors"
        ></v-text-field>
          </v-flex>
        </v-row>
        <v-row>
          <v-flex xs4>
            <v-text-field
              class="mx-2"
              v-model="payload.zipCode"
              :validate-on-blur="false"
              :rules="[rules.required]"
              :error="error"
              :label="t('$vuetify.serviceCenter.zipCode')"
              name="zip_code"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
          <v-flex xs8>
        <v-text-field
          class="mx-2"
          v-model="payload.city"
          :rules="[rules.required]"
          :validate-on-blur="false"
          :error="error"
          :label="t('$vuetify.serviceCenter.city')"
          name="city"
          @keyup.enter="submit"
          @change="resetErrors"
        ></v-text-field>
          </v-flex>
        </v-row>
        <v-row>
          <v-flex xs12>
        <v-text-field
          class="mx-2"
          v-model="payload.email"
          :rules="[rules.required, rules.email]"
          :validate-on-blur="false"
          :error="error"
          :label="t('$vuetify.serviceCenter.email')"
          name="email"
          @keyup.enter="submit"
          @change="resetErrors"
        ></v-text-field>
          </v-flex>
        </v-row>
        <v-row>
          <v-flex xs12>
        <v-text-field
          class="mx-2"
          v-model="payload.phone"
          :rules="[rules.required, rules.phone]"
          :validate-on-blur="false"
          :error="error"
          :label="t('$vuetify.serviceCenter.phone')"
          name="phone"
          @keyup.enter="submit"
          @change="resetErrors"
        ></v-text-field>
          </v-flex>
        </v-row>
    </v-card-text>
      </v-form>
              <v-card-text v-else>
                Thank you for your request. We will contact you soon.
              </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-end" v-if="!isSaved">
      <v-btn
        color="primary"
        text
        @click="previousStep()"
      >
        <v-icon>mdi-chevron-left</v-icon>
        {{t('$vuetify.generic.back')}}
      </v-btn>
      <v-btn
        color="success"
        text
        @click="nextStep()"
        :loading="isSaveRequest"
        :disabled="isSaving"
      >
        {{t('$vuetify.generic.save')}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="cancel"
      >
        {{t('$vuetify.generic.cancel')}}
      </v-btn>
    </v-card-actions>
    <v-card-actions class="justify-end" v-else>
      <v-btn
        color="primary"
        text
        v-if="isSaved"
        @click="closeRQ"
      >
        {{t('$vuetify.generic.close')}}
      </v-btn>
  </v-card-actions>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import RequestApi from '../../../services/RequestApi'
import UserApi from '@/services/UserApi'

export default {
  data () {
    return {
      isSaved: false,
      langs: ['en', 'de'],

      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,
      isSaveRequest: false,
      isSaving: false,

      // form
      isFormValid: true,
      email: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required',
        vatCheck: v => {
          if (/^(BE)?0[0-9]{9}$/.test(v)) return true
          return 'invalide vat should be lik BEXXXXXXXXXX'
        },
        zipCode: v => {
          if (!v.trim()) return true
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999) return true
          return 'Number has to be between 0 and 9999'
        },
        email: v => {
          if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v)) return true
          return 'Invalid email'
        },
        phone: v => {
          if (/^(?:(?:\+|00)32|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(v)) return true
          return 'Invalid phone should be like 04XXXXXXXX'
        }
      },

      payload: {
        sexe: 'Mr.',
        companyName: '',
        vat: '',
        name: '',
        address: '',
        addressBis: '',
        zipCode: '',
        city: '',
        country: '',
        email: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapGetters({ previousStepName: 'requestsState/previousStepName', elmState: 'requestsState/elmState', userDT: 'user/userInfo' })
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const dt = await new UserApi().userInfo()
      this.payload.email = dt.data.data.RelationContact.Email // E-mail address - string
      this.payload.phone = dt.data.data.RelationContact.Telephone // Phone number - string
      this.payload.address = dt.data.data.RelationContact.Street + ' ' + dt.data.data.RelationContact.HouseNumber // Street Address (delivery) - string
      this.payload.name = dt.data.data.RelationContact.Name
      this.payload.zipCode = dt.data.data.RelationContact.PostalCode // Postal Code (delivery) - string
      this.payload.city = dt.data.data.RelationContact.City
      this.payload.companyName = dt.data.data.Relation.Name
      this.payload.vat = dt.data.data.Relation.VatNr
    },
    nextStep () {
      this.submit()
    },

    cancel () {
      this.cleanPayload()
      this.$emit('cancel')
      this.init()
    },

    closeRQ () {
      this.cleanPayload()
      this.$store.commit('requestsState/setStep', 'AccountType')
      this.$emit('closeRQ')
    },

    previousStep () {
      this.cleanPayload()
      this.resetErrors()
      this.$store.commit('requestsState/setCompanyDetails', this.payload)
      this.$store.commit('requestsState/setPreviousStepName')
    },

    cleanPayload () {
      this.payload = {
        sexe: 'Mr.',
        companyName: '',
        vat: '',
        name: '',
        address: '',
        addressBis: '',
        zipCode: '',
        city: '',
        country: '',
        email: '',
        phone: ''
      }
      this.payload.email = this.elmState.customfield_12509 // E-mail address - string
      this.payload.phone = this.elmState.customfield_12508 // Phone number - string
      this.payload.address = this.elmState.customfield_15742 // Street Address (delivery) - string
      this.payload.zipCode = this.elmState.customfield_15743 // Postal Code (delivery) - string
      this.payload.city = this.elmState.customfield_15744
    },

    async save () {
      try {
        const payload = this.elmState
        const data = await new RequestApi().createChargePointRequest(payload)
        this.isSaveRequest = false
        this.isSaving = false
        this.isSaved = data.result.data.Status !== '400'
      } catch (err) {
        console.log(err)
      }
    },

    submit () {
      if (this.$refs.form.validate()) {
        this.$store.commit('requestsState/setCompanyDetails', this.payload)
        this.isSaveRequest = true
        this.isSaving = true
        this.save()
      }
    },

    resetErrors () {
      this.error = false
      this.errorMessages = ''

      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
